<template>
    <div :class="isPreview ? 'pdf-preview' : 'pdf-preview-no-footer'">
      <div class="iframe-wrapper">
        <pdf :src="loadingTask"></pdf>
        <!-- <iframe :src="src" frameborder="0" class="myIframe"></iframe> -->
      </div>
        <div v-if="isPreview" class="common-footer">
          <van-button class="btn back" @click="onBack">{{$t('返回')}}</van-button>
          <van-button class="btn submit" @click="onSend">{{$t('发送')}}</van-button>
        </div>
    </div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import vendorServices from '@/services/vendorServices'
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'

// var loadingTask = pdf.createLoadingTask('https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/dsso-file/f2b3fdc97d5f4e3ab229aba908b8e289.pdf?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1721786691%3B1721822691&q-key-time=1721786691%3B1721822691&q-header-list=host&q-url-param-list=&q-signature=a57dde9e8c48b1ecd7136973ff9cc7d3f9ea64e3')

export default {
  components: { pdf },
  data(){
    return {
      loadingTask: {},
      src: '',
      isPreview: false,
      id: '',
      code: ''
    }
  },
  mounted(){
    const { procotolCode,id,isPreview } = this.$route.query
    this.isPreview = isPreview === 'true'
    this.code = procotolCode
    this.id = id
    this.getPdfSrc()
    if (this.isPreview){
      this.isWxReady()
    }
  },
  methods:{
    getPdfSrc(){
      loading.showLoading()
      deliveryServices.getProtocolLink(this.code,this.id).then(res=>{
        if (res?.linkUrl){
          this.src = res.linkUrl
          this.loadingTask = pdf.createLoadingTask({
            url: this.src,
            // 引入pdf.js字体，否则后端填充的字体读不出来
            CMapReaderFactory
            // cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',
            // cMapPacked: true
          })
        }
      }).finally(()=>{
        loading.hideLoading()
      })
    },
    // 判断是否具备发送能力
    isWxReady(){
      vendorServices.wxAgentConfig(
        ()=>{
          // this.$toast('具备发送能力')
        },
        ()=>{
          this.$toast('wx注册失败')
        })
    },
    onBack(){
      this.$router.back()
    },
    onSend(){
      console.log('onSend')
      const linkUrl = `${window.location.origin}${window.location.pathname}?id=${this.id}&procotolCode=${this.code}`
      const title = `${this.code==='3004005' ? '提车催促函' : this.code==='3004004' ? '交付通知' : ''}`
      console.log('linkUrl:',linkUrl)
      console.log('title:',title)
      const params = {
        link: linkUrl,
        title,
        desc: '',
        imgUrl: ''
      }
      wx.invoke( 'shareAppMessage', params, function (res) {
        console.log('shareAppMessage:',res)
        if (!`${res.err_msg}`.startsWith('shareAppMessage:ok')){
          this.$toast('发送失败: ',res.err_msg)
        }
      })
    }
  }
}

</script>
<style lang="less" scoped>
.pdf-preview{
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 96px;
  .iframe-wrapper{
    width: 100%;
    height: calc(100vh - 96px);
    overflow: hidden;
    .myIframe{
      width: 100%;
      height: 100%;
    }
  }
}

.pdf-preview-no-footer{
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 16px;
  .iframe-wrapper{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .myIframe{
      width: 100%;
      height: 100%;
    }
  }
}
</style>